import React from "react"
import Plx from "react-plx"
import styled from "@emotion/styled"
import ParallaxImageOne from "../shared/ParallaxImageOne"
import ParallaxImageTwo from "../shared/ParallaxImageTwo"
import { breakpoints } from "../../utils/style"

const ParallaxContainer = styled("div")`
  position: relative;
  z-index: -100;
  .bg {
    display: block;
    width: 90%;
    position: fixed;
    top: 0;
    color: #000;
    svg {
      width: 100%;
    }
  }
  .bg-one {
    top: 0;
    left: 3%;
    width: 100%;
    .gatsby-image-wrapper {
      margin-left: -90px;
    }
  }
  .bg-two {
    right: 0;
    top: 0;
    width: 100%;
    .gatsby-image-wrapper {
      margin-left: -10%;
    }
  }
  
  @media (max-width: ${breakpoints.sm}) {
    .bg-one {
      top: -100px;
      max-height: 100vh;
    }
  }
`

const ParallaxHome = () => {
  const image1ParallaxData = [
    {
      start: 0,
      duration: 4500,
      properties: [
        {
          startValue: 150,
          endValue: 210,
          property: "translateY",
        },
      ],
    },
    {
      start: 800,
      duration: 5000,
      properties: [
        {
          startValue: 160,
          endValue: -1200,
          property: "translateY",
        },
      ],
    },
  ]

  const image2ParallaxData = [
    {
      start: -200,
      duration: 5500,
      properties: [
        {
          startValue: -450,
          endValue: 1000,
          property: "translateY",
        },
      ],
    },
  ]

  return (
    <ParallaxContainer>
      <Plx className="bg bg-one" parallaxData={image1ParallaxData}>
        <ParallaxImageOne />
      </Plx>
      <Plx className="bg bg-two" parallaxData={image2ParallaxData}>
        <ParallaxImageTwo />
      </Plx>
    </ParallaxContainer>
  )
}

export default ParallaxHome
