import React from "react"
const ParallaxImageTwo = ({
  width = "1980",
  height = "530",
  viewBox = "0 0 1980 530",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M160.507 412.535L140.267 369.266C123.419 267.532 179.157 218.354 249.412 162.633C221.013 181.734 193.356 200.519 161.884 198.197C140.267 196.614 120.982 193.659 107.842 207.906L115.789 237.561L54.8588 255.185L34.6193 211.917L26.354 146.275L82.9398 129.812L90.2514 157.462C113.67 114.721 149.804 102.901 188.694 101.635C240.405 99.946 269.546 105.223 302.925 71.5576L326.132 63.5371L342.026 108.389C263.718 207.801 262.764 257.929 303.455 371.166L160.507 412.535Z"
          fill="url(#paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M139.843 369.161C107.418 247.165 151.5 180.89 228.855 119.575C200.562 138.36 172.799 157.462 141.221 154.929C119.604 153.346 100.318 150.391 87.2844 164.849L95.2318 194.293L34.6194 212.022L5.79663 103.218L62.4884 86.6489L69.8 114.299C93.1125 71.3466 129.247 59.5269 168.454 58.366C220.06 56.4664 249.094 62.0597 282.368 28.1836L326.026 62.6929C247.505 162.211 242.312 214.766 283.215 328.003L139.843 369.161Z"
          fill="url(#paint1_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M145.036 360.507C114.941 245.054 165.487 176.246 241.677 118.414L234.789 108.811C206.92 126.012 169.938 150.285 142.069 147.225C112.61 143.742 93.7483 144.586 79.7607 164.215L86.7545 189.754L38.3281 203.79L12.5784 106.911L58.1437 93.9304L68.2105 128.229C90.6752 87.0708 122.359 68.497 158.493 66.7029C208.085 64.0646 250.366 69.0246 282.262 36.626L316.701 64.4867C241.677 159.889 234.153 214.66 273.36 323.57L145.036 360.507Z"
          fill="url(#paint2_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135.605 73.7733C183.714 62.7978 241.995 82.8491 282.58 42.0078L311.402 64.803L283.11 50.7671C235.213 83.9044 187.528 70.9239 135.605 73.7733Z"
          fill="url(#paint3_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9167 106.383L55.3886 97.9404L63.6539 127.173L51.9977 103.639L23.9167 106.383Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.4734 157.989L40.4475 200.414L76.4759 190.282C48.289 189.016 43.6265 180.995 28.4734 157.989Z"
          fill="url(#paint4_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.38 353.225C142.493 327.158 140.055 303.519 141.433 282.096C153.619 317.344 183.501 332.435 214.231 334.44L149.38 353.225Z"
          fill="url(#paint5_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215.927 116.409C189.329 131.289 168.878 145.431 138.36 142.792C117.484 140.893 103.921 142.159 84.1052 154.612C101.907 136.566 118.756 134.983 139.313 134.561C168.136 133.717 189.011 128.123 215.927 116.409Z"
          fill="url(#paint6_linear)"
        />
      </g>
      <g opacity="0.2" clipPath="url(#clip1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1929.49 283.407C1942.58 332.023 1930.63 377.455 1910.04 411.211L1904.24 407.248C1928.36 364.576 1931.19 317.799 1921.22 283.548L1929.49 283.407Z"
          fill="url(#paint7_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1929.07 283.265C1879.48 274.49 1835.41 290.908 1803.72 314.544L1808.32 319.922C1848.64 291.686 1894.62 284.68 1929.85 291.332L1929.07 283.265Z"
          fill="url(#paint8_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1923.2 290.2C1871.7 277.675 1862.93 242.999 1871.77 185.82C1916.41 207.616 1942.3 238.329 1930.27 282.487C1973 266.211 2005.96 289.21 2031.92 331.599C1975.83 345.894 1940.39 340.232 1923.2 290.2Z"
          fill="url(#paint9_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1911.31 402.224C1894.05 335.916 1819.21 376.323 1814.9 437.395C1810.3 500.306 1902.4 542.625 1943.71 489.691C1978.23 445.321 1965.5 386.231 1911.31 402.224Z"
          fill="url(#paint10_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1812.56 312.35C1880.19 323.461 1847.15 401.799 1786.67 411.848C1724.49 422.109 1673.63 334.43 1722.51 288.29C1763.33 249.722 1823.24 256.94 1812.56 312.35Z"
          fill="url(#paint11_linear)"
        />
        <path
          opacity="0.53"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1824.94 464.64C1842.63 507.1 1908.06 528.471 1940.39 487.002C1953.26 470.584 1958.92 452.043 1958.14 436.545C1924.47 488.205 1864.63 486.719 1824.94 464.64Z"
          fill="url(#paint12_linear)"
        />
        <path
          opacity="0.49"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1844.04 334.996C1854.3 361.179 1826.57 400.596 1785.68 407.46C1748.97 413.476 1716.36 383.683 1708.15 349.644C1735.18 392.599 1823.24 382.551 1844.04 334.996Z"
          fill="url(#paint13_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1909.54 407.673C1898.86 362.948 1857.91 362.028 1832.86 398.049C1857.98 369.247 1896.46 368.539 1907.07 412.839C1916.12 406.965 1921.99 405.479 1931.97 401.658C1923.41 402.436 1917.54 404.064 1909.54 407.673Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1820.34 317.304C1816.31 316.313 1812.42 315.464 1808.39 314.473C1817.16 280.222 1789.93 250.642 1735.81 280.718C1786.11 261.328 1808.53 278.028 1806.2 316.738C1810.87 316.738 1815.6 316.95 1820.34 317.304Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1892.5 202.096C1917.75 220.779 1933.1 238.895 1929.85 271.943C1927.8 239.319 1915.35 225.095 1892.5 202.096Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2026.62 328.414C2008.93 302.372 1986.08 278.312 1952.84 280.293C1985.45 283.548 2004.48 304.637 2026.62 328.414Z"
          fill="white"
        />
        <path
          opacity="0.39"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1872.27 208.395C1876.93 261.894 1931.47 287.795 1904.31 233.8C1910.11 239.249 1913.72 247.245 1918.03 258.002C1922.07 268.475 1923.48 280.859 1928.79 278.1C1927.58 281.638 1926.45 284.539 1924.68 287.865C1877.93 275.764 1868.94 247.528 1872.27 208.395Z"
          fill="url(#paint14_linear)"
        />
        <path
          opacity="0.39"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2002.35 334.359C1957.22 326.928 1908.41 274.42 1977.95 305.062C1969.04 298.339 1957.72 292.394 1946.75 290.342C1941.87 289.422 1937.34 288.502 1940.39 282.982C1935.51 284.185 1930.27 286.096 1925.89 288.785C1938.76 326.291 1965 337.19 2002.35 334.359Z"
          fill="url(#paint15_linear)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="254.416"
        y1="387.642"
        x2="184.858"
        y2="146.305"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#824201" />
        <stop offset="1" stopColor="#BE771A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="157.965"
        y1="60.3924"
        x2="239.31"
        y2="342.623"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF8A5" />
        <stop offset="1" stopColor="#D56900" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="157.906"
        y1="217.088"
        x2="169.735"
        y2="-15.6869"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="296.448"
        y1="53.5657"
        x2="167.962"
        y2="76.4972"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="34.3373"
        y1="179.325"
        x2="70.2935"
        y2="168.877"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="139.508"
        y1="319.328"
        x2="204.363"
        y2="300.482"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="84.2341"
        y1="154.787"
        x2="215.933"
        y2="116.517"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="1899.1"
        y1="339.1"
        x2="1935.63"
        y2="353.626"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="1817.04"
        y1="281.034"
        x2="1918.02"
        y2="321.195"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="1909.01"
        y1="320.574"
        x2="1971.11"
        y2="230.913"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AF650C" />
        <stop offset="0.5469" stopColor="#DAAA51" />
        <stop offset="1" stopColor="#FFE68D" />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1="1887.87"
        y1="508.486"
        x2="1889.71"
        y2="376.073"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AF650C" />
        <stop offset="0.5469" stopColor="#DAAA51" />
        <stop offset="1" stopColor="#FFE68D" />
      </linearGradient>
      <linearGradient
        id="paint11_linear"
        x1="1787.59"
        y1="433.057"
        x2="1767.8"
        y2="269.956"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AF650C" />
        <stop offset="0.5469" stopColor="#DAAA51" />
        <stop offset="1" stopColor="#FFE68D" />
      </linearGradient>
      <linearGradient
        id="paint12_linear"
        x1="1834.98"
        y1="439.401"
        x2="1945.72"
        y2="483.445"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#824201" />
        <stop offset="1" stopColor="#BE771A" />
      </linearGradient>
      <linearGradient
        id="paint13_linear"
        x1="1713.81"
        y1="335.563"
        x2="1834.71"
        y2="383.649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#824201" />
        <stop offset="1" stopColor="#BE771A" />
      </linearGradient>
      <linearGradient
        id="paint14_linear"
        x1="1862.04"
        y1="234.118"
        x2="1934.94"
        y2="263.11"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#824201" />
        <stop offset="1" stopColor="#BE771A" />
      </linearGradient>
      <linearGradient
        id="paint15_linear"
        x1="1923.26"
        y1="295.319"
        x2="2004.95"
        y2="327.81"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#824201" />
        <stop offset="1" stopColor="#BE771A" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="1980" height="531" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect
          width="278"
          height="397"
          fill="white"
          transform="translate(1702 136)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default ParallaxImageTwo
