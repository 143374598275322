import React from "react"
const ParallaxImageOne = ({
  width = "1980",
  height = "1251",
  viewBox = "0 0 1980 1251",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.2">
      <g filter="url(#filter0_f)">
        <path
          d="M186.059 118.546L188.806 107.733C187.92 107.821 187.034 107.91 186.059 107.91C171.7 107.91 160 96.2101 160 81.8513C160 70.5947 167.268 60.8449 177.55 57.2109C177.55 56.7677 177.55 56.4131 177.55 55.97C177.638 41.6998 189.249 30 203.608 30C217.967 30 229.578 41.6998 229.578 55.97C229.578 56.4131 229.578 56.7677 229.578 57.2109C239.86 60.7563 247.128 70.5947 247.128 81.8513C247.128 96.2101 235.428 107.91 221.069 107.91C220.183 107.91 219.208 107.821 218.322 107.733L221.069 118.546H186.059Z"
          fill="url(#paint0_linear)"
        />
        <path
          opacity="0.3"
          d="M192.529 113.493L195.897 100.376C192.884 101.971 189.515 102.857 186.059 102.857C174.536 102.857 165.141 93.462 165.141 81.9395C165.141 71.3034 173.029 62.5285 183.311 61.199C182.868 59.5149 182.691 57.8309 182.691 56.1468C182.691 44.447 192.086 35.0518 203.608 35.0518C215.131 35.0518 224.526 44.447 224.526 55.9695C224.526 57.6536 224.349 59.3377 223.906 61.0217C234.099 62.4399 242.076 71.2147 242.076 81.7623C242.076 93.2848 232.681 102.68 221.158 102.68C217.701 102.68 214.333 101.794 211.32 100.198L214.688 113.316H192.529V113.493Z"
          fill="white"
        />
        <path
          d="M221.158 63.4145C220.981 63.4145 220.715 63.4145 220.537 63.4145C221.512 61.11 222.133 58.6283 222.133 55.9692C222.133 45.7762 213.89 37.5332 203.697 37.5332C193.504 37.5332 185.261 45.7762 185.261 55.9692C185.261 58.6283 185.793 61.11 186.856 63.4145C186.679 63.4145 186.413 63.4145 186.236 63.4145C175.954 63.4145 167.711 71.6576 167.711 81.8506C167.711 92.0436 175.954 100.287 186.147 100.287C191.908 100.287 196.961 97.7162 200.329 93.5504L195.808 110.923H211.408L206.888 93.5504C210.256 97.6275 215.397 100.287 221.069 100.287C231.262 100.287 239.505 92.0436 239.505 81.8506C239.505 71.6576 231.262 63.4145 221.158 63.4145Z"
          fill="url(#paint1_linear)"
        />
        <path
          opacity="0.3"
          d="M221.158 64.5671C220.981 64.5671 220.804 64.5671 220.626 64.5671L218.676 64.6558L219.385 62.9717C220.36 60.7558 220.804 58.4513 220.804 56.0582C220.804 46.5743 213.092 38.7744 203.52 38.7744C194.036 38.7744 186.236 46.4856 186.236 56.0582C186.236 58.4513 186.768 60.8445 187.654 62.9717L188.363 64.6558L186.591 64.5671C186.413 64.5671 186.236 64.5671 186.059 64.5671C180.386 64.5671 175.334 67.3148 172.232 71.5693C180.209 76.7987 191.288 80.0782 203.608 80.0782C215.929 80.0782 227.008 76.7987 234.985 71.5693C231.794 67.3148 226.831 64.5671 221.158 64.5671Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_f)">
        <path
          d="M1904.35 444.535C1902.34 437.026 1894.68 428.511 1887.39 425.673L1818.75 399.065C1811.45 396.286 1801.78 399.124 1797.21 405.51L1762.75 453.937C1758.18 460.323 1754.86 471.912 1755.27 479.717L1758.3 533.465C1758.71 541.27 1765.3 549.253 1772.89 551.204L1844.97 569.889C1852.56 571.84 1863.3 568.943 1868.88 563.444L1911.95 520.693C1917.52 515.194 1920.37 504.492 1918.35 496.923L1904.35 444.535Z"
          fill="url(#paint2_linear)"
        />
        <path
          d="M1807.18 407.934C1806.11 400.188 1811.21 396.167 1818.51 399.005L1887.15 425.614C1894.44 428.452 1902.1 436.966 1904.11 444.476L1918.17 496.864C1920.19 504.433 1915.74 508.631 1908.33 506.147L1830.32 480.663C1822.84 478.238 1815.9 469.901 1814.83 462.155L1807.18 407.934Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M1807.77 487.285C1812.7 481.194 1822.84 478.238 1830.26 480.662L1908.27 506.147C1915.74 508.571 1917.28 515.075 1911.71 520.574L1868.64 563.325C1863.06 568.824 1852.33 571.721 1844.73 569.77L1772.65 551.144C1765.06 549.193 1762.93 542.57 1767.85 536.539L1807.77 487.285Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M1796.98 405.45C1801.49 399.065 1806.11 400.188 1807.18 407.934L1814.77 462.155C1815.84 469.901 1812.7 481.195 1807.77 487.285L1767.85 536.54C1762.93 542.63 1758.54 541.211 1758.06 533.406L1755.04 479.598C1754.62 471.793 1757.94 460.204 1762.51 453.818L1796.98 405.45Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M1887.8 441.46C1887.68 441.105 1887.56 440.691 1887.39 440.277C1887.21 439.863 1886.97 439.449 1886.73 439.035C1886.5 438.621 1886.2 438.208 1885.9 437.734C1885.61 437.321 1885.25 436.907 1884.83 436.434C1884 435.547 1883.05 434.719 1882.05 434.009C1881.1 433.359 1880.03 432.768 1879.08 432.354C1878.07 431.94 1877.12 431.644 1876.23 431.585C1875.34 431.526 1874.57 431.585 1873.92 431.881C1873.56 432.058 1873.27 432.235 1873.03 432.472C1872.79 432.709 1872.61 433.004 1872.5 433.359C1872.38 433.714 1872.32 434.068 1872.32 434.482C1872.32 434.896 1872.38 435.31 1872.44 435.783C1872.5 436.138 1872.67 436.552 1872.85 436.966C1873.03 437.38 1873.21 437.794 1873.5 438.267C1873.74 438.681 1874.04 439.154 1874.39 439.567C1874.75 439.981 1875.11 440.454 1875.46 440.868C1876.35 441.814 1877.3 442.642 1878.31 443.352C1879.32 444.061 1880.33 444.593 1881.33 445.007C1882.34 445.421 1883.29 445.658 1884.18 445.776C1885.07 445.835 1885.84 445.717 1886.5 445.421C1886.85 445.244 1887.09 445.066 1887.33 444.771C1887.56 444.534 1887.68 444.18 1887.8 443.884C1887.92 443.529 1887.98 443.174 1887.98 442.76C1887.98 442.347 1887.92 441.933 1887.8 441.46Z"
          fill="url(#paint6_linear)"
        />
        <path
          d="M1848.47 467.004C1848.41 466.59 1848.23 466.117 1848.11 465.644C1847.94 465.171 1847.76 464.698 1847.46 464.225C1847.22 463.752 1846.93 463.279 1846.57 462.806C1846.22 462.333 1845.86 461.86 1845.44 461.387C1844.49 460.382 1843.49 459.495 1842.42 458.726C1841.35 457.957 1840.22 457.366 1839.1 456.952C1837.97 456.538 1836.9 456.243 1835.83 456.183C1834.82 456.124 1833.88 456.243 1833.1 456.597C1832.69 456.775 1832.33 457.011 1832.04 457.366C1831.74 457.662 1831.5 458.016 1831.38 458.43C1831.21 458.844 1831.15 459.258 1831.09 459.731C1831.03 460.204 1831.09 460.677 1831.15 461.209C1831.21 461.623 1831.32 462.096 1831.5 462.569C1831.68 463.042 1831.86 463.515 1832.16 463.989C1832.39 464.462 1832.69 464.994 1833.05 465.467C1833.4 465.94 1833.76 466.472 1834.23 466.945C1835.18 468.009 1836.25 468.896 1837.32 469.665C1838.44 470.434 1839.57 471.025 1840.76 471.498C1841.88 471.912 1843.01 472.207 1844.02 472.267C1845.03 472.326 1845.98 472.148 1846.75 471.794C1847.16 471.616 1847.52 471.321 1847.76 471.025C1848.05 470.729 1848.23 470.374 1848.41 469.961C1848.59 469.547 1848.65 469.133 1848.65 468.66C1848.59 468.009 1848.53 467.536 1848.47 467.004Z"
          fill="url(#paint7_linear)"
        />
        <path
          d="M1802.14 538.728C1802.43 538.374 1802.67 538.019 1802.85 537.723C1803.03 537.368 1803.21 537.014 1803.32 536.659C1803.44 536.304 1803.44 535.89 1803.44 535.535C1803.44 535.181 1803.38 534.767 1803.21 534.412C1802.91 533.643 1802.38 532.934 1801.6 532.342C1800.83 531.751 1799.88 531.278 1798.82 530.923C1797.75 530.569 1796.5 530.332 1795.26 530.273C1793.95 530.214 1792.65 530.332 1791.34 530.569C1790.63 530.746 1789.98 530.923 1789.32 531.16C1788.67 531.396 1788.08 531.633 1787.54 531.928C1787.01 532.224 1786.48 532.579 1786.06 532.934C1785.65 533.288 1785.23 533.643 1784.87 534.057C1784.58 534.412 1784.34 534.767 1784.16 535.121C1783.98 535.476 1783.81 535.831 1783.75 536.245C1783.69 536.6 1783.63 537.014 1783.63 537.368C1783.63 537.723 1783.75 538.137 1783.87 538.492C1784.16 539.26 1784.76 539.97 1785.53 540.561C1786.3 541.153 1787.25 541.626 1788.32 541.98C1789.38 542.335 1790.57 542.513 1791.82 542.572C1793.06 542.631 1794.37 542.513 1795.67 542.276C1796.38 542.158 1797.04 541.921 1797.69 541.744C1798.34 541.507 1798.88 541.271 1799.47 540.975C1800 540.68 1800.48 540.384 1800.95 540.029C1801.43 539.497 1801.78 539.142 1802.14 538.728Z"
          fill="url(#paint8_linear)"
        />
        <path
          d="M1859.5 554.516C1859.8 554.221 1860.1 553.866 1860.27 553.57C1860.51 553.216 1860.69 552.92 1860.81 552.565C1860.93 552.21 1860.99 551.856 1861.05 551.56C1861.05 551.205 1861.05 550.85 1860.93 550.555C1860.75 549.845 1860.27 549.195 1859.62 548.603C1858.97 548.071 1858.14 547.598 1857.13 547.303C1856.12 547.007 1855.05 546.77 1853.81 546.711C1852.62 546.652 1851.38 546.77 1850.07 547.007C1849.36 547.125 1848.71 547.303 1848.11 547.539C1847.52 547.776 1846.93 548.012 1846.33 548.308C1845.8 548.603 1845.27 548.899 1844.85 549.254C1844.38 549.609 1844.02 549.904 1843.66 550.318C1843.37 550.614 1843.13 550.969 1842.89 551.323C1842.66 551.678 1842.54 551.974 1842.42 552.329C1842.3 552.683 1842.24 553.038 1842.24 553.393C1842.24 553.748 1842.24 554.102 1842.36 554.398C1842.6 555.108 1843.07 555.758 1843.72 556.29C1844.38 556.822 1845.21 557.236 1846.21 557.591C1847.16 557.887 1848.29 558.064 1849.48 558.123C1850.66 558.182 1851.91 558.064 1853.16 557.828C1853.81 557.65 1854.46 557.532 1855.05 557.295C1855.65 557.059 1856.24 556.822 1856.77 556.586C1857.31 556.29 1857.78 555.995 1858.26 555.699C1858.73 555.167 1859.15 554.871 1859.5 554.516Z"
          fill="url(#paint9_linear)"
        />
        <path
          d="M1845.62 531.043C1845.92 530.688 1846.22 530.333 1846.45 529.978C1846.69 529.624 1846.87 529.269 1846.99 528.855C1847.11 528.5 1847.16 528.086 1847.22 527.731C1847.22 527.377 1847.22 526.963 1847.11 526.608C1846.87 525.78 1846.39 525.071 1845.68 524.479C1844.97 523.888 1844.08 523.356 1843.07 523.001C1842 522.646 1840.88 522.41 1839.57 522.351C1838.33 522.292 1836.96 522.351 1835.66 522.646C1834.94 522.765 1834.23 523.001 1833.58 523.238C1832.93 523.474 1832.33 523.77 1831.74 524.065C1831.21 524.361 1830.67 524.716 1830.2 525.071C1829.72 525.425 1829.31 525.839 1828.95 526.194C1828.66 526.549 1828.36 526.904 1828.18 527.258C1827.94 527.613 1827.82 528.027 1827.71 528.382C1827.59 528.737 1827.53 529.151 1827.53 529.505C1827.53 529.86 1827.59 530.274 1827.71 530.629C1827.94 531.457 1828.48 532.107 1829.19 532.698C1829.9 533.29 1830.79 533.763 1831.86 534.117C1832.87 534.472 1834.05 534.65 1835.3 534.709C1836.55 534.768 1837.85 534.65 1839.16 534.413C1839.87 534.295 1840.52 534.058 1841.17 533.881C1841.83 533.644 1842.42 533.408 1842.95 533.112C1843.49 532.817 1844.02 532.462 1844.49 532.166C1844.85 531.811 1845.27 531.457 1845.62 531.043Z"
          fill="url(#paint10_linear)"
        />
        <path
          d="M1831.62 505.321C1831.92 504.966 1832.21 504.552 1832.45 504.197C1832.69 503.783 1832.87 503.369 1832.99 503.015C1833.1 502.601 1833.16 502.187 1833.16 501.773C1833.16 501.359 1833.1 500.945 1832.99 500.531C1832.75 499.644 1832.21 498.876 1831.44 498.166C1830.67 497.516 1829.72 496.924 1828.6 496.57C1827.47 496.156 1826.22 495.919 1824.92 495.801C1823.61 495.742 1822.19 495.801 1820.77 496.097C1819.99 496.274 1819.28 496.451 1818.63 496.688C1817.98 496.924 1817.32 497.22 1816.73 497.575C1816.14 497.93 1815.6 498.284 1815.13 498.639C1814.65 499.053 1814.24 499.467 1813.82 499.881C1813.53 500.236 1813.23 500.649 1812.99 501.063C1812.76 501.477 1812.64 501.891 1812.52 502.305C1812.4 502.719 1812.34 503.133 1812.34 503.547C1812.34 503.961 1812.4 504.375 1812.52 504.789C1812.82 505.675 1813.35 506.444 1814.12 507.095C1814.89 507.745 1815.84 508.277 1816.97 508.691C1818.1 509.105 1819.28 509.341 1820.59 509.401C1821.89 509.46 1823.26 509.401 1824.62 509.105C1825.33 508.928 1826.05 508.75 1826.7 508.514C1827.35 508.277 1828 507.981 1828.6 507.686C1829.19 507.39 1829.72 507.035 1830.2 506.622C1830.85 506.148 1831.27 505.735 1831.62 505.321Z"
          fill="url(#paint11_linear)"
        />
        <path
          d="M1890.47 523.415C1890.83 523.06 1891.12 522.705 1891.36 522.35C1891.6 521.996 1891.83 521.582 1891.95 521.227C1892.13 520.872 1892.19 520.458 1892.25 520.104C1892.31 519.749 1892.31 519.335 1892.19 518.98C1892.01 518.152 1891.6 517.443 1891 516.851C1890.35 516.201 1889.52 515.728 1888.51 515.373C1887.5 515.018 1886.38 514.782 1885.13 514.723C1883.89 514.664 1882.58 514.723 1881.22 515.018C1880.5 515.196 1879.79 515.373 1879.14 515.61C1878.49 515.846 1877.83 516.142 1877.3 516.438C1876.71 516.733 1876.17 517.088 1875.7 517.443C1875.22 517.797 1874.75 518.211 1874.39 518.566C1874.04 518.921 1873.8 519.276 1873.5 519.63C1873.27 519.985 1873.09 520.399 1872.91 520.754C1872.79 521.109 1872.67 521.523 1872.67 521.877C1872.61 522.291 1872.67 522.646 1872.73 523.001C1872.91 523.829 1873.39 524.538 1874.04 525.129C1874.69 525.721 1875.52 526.194 1876.53 526.549C1877.54 526.903 1878.67 527.14 1879.85 527.199C1881.1 527.258 1882.34 527.14 1883.65 526.903C1884.36 526.785 1885.01 526.549 1885.67 526.371C1886.32 526.135 1886.91 525.898 1887.5 525.603C1888.1 525.307 1888.63 524.952 1889.11 524.597C1889.64 524.183 1890.06 523.829 1890.47 523.415Z"
          fill="url(#paint12_linear)"
        />
        <path
          d="M1764.59 461.033C1764.35 461.388 1764.11 461.742 1763.82 462.156C1763.58 462.57 1763.34 462.984 1763.1 463.457C1762.87 463.93 1762.63 464.403 1762.45 464.935C1762.27 465.468 1762.04 466 1761.86 466.532C1761.5 467.714 1761.2 468.897 1761.03 469.961C1760.85 471.026 1760.79 472.09 1760.79 472.977C1760.85 473.864 1760.97 474.633 1761.15 475.224C1761.38 475.815 1761.74 476.229 1762.15 476.347C1762.39 476.406 1762.63 476.466 1762.93 476.406C1763.16 476.347 1763.46 476.229 1763.76 476.052C1764.05 475.874 1764.35 475.638 1764.65 475.342C1764.94 475.046 1765.24 474.692 1765.54 474.278C1765.77 473.923 1766.07 473.568 1766.31 473.154C1766.54 472.74 1766.78 472.267 1767.02 471.854C1767.26 471.38 1767.49 470.848 1767.67 470.375C1767.91 469.843 1768.09 469.311 1768.26 468.779C1768.68 467.537 1768.92 466.355 1769.09 465.231C1769.27 464.108 1769.33 463.102 1769.27 462.156C1769.21 461.269 1769.09 460.501 1768.86 459.909C1768.62 459.318 1768.26 458.963 1767.85 458.845C1767.61 458.786 1767.37 458.786 1767.08 458.845C1766.84 458.904 1766.54 459.023 1766.25 459.259C1765.95 459.436 1765.65 459.673 1765.36 460.028C1765.18 460.264 1764.88 460.619 1764.59 461.033Z"
          fill="url(#paint13_linear)"
        />
        <path
          d="M1798.22 464.698C1797.93 465.053 1797.63 465.526 1797.33 465.94C1797.04 466.413 1796.8 466.886 1796.5 467.418C1796.26 467.95 1795.97 468.482 1795.73 469.074C1795.49 469.665 1795.26 470.256 1795.08 470.848C1794.66 472.148 1794.37 473.449 1794.19 474.691C1794.01 475.933 1793.95 477.056 1794.01 478.061C1794.07 479.067 1794.25 479.954 1794.6 480.604C1794.9 481.254 1795.32 481.727 1795.91 481.905C1796.21 482.023 1796.5 482.023 1796.86 481.964C1797.15 481.905 1797.51 481.787 1797.87 481.55C1798.22 481.373 1798.58 481.077 1798.93 480.722C1799.29 480.367 1799.65 480.013 1800 479.54C1800.3 479.185 1800.6 478.712 1800.89 478.239C1801.19 477.766 1801.49 477.293 1801.72 476.761C1802.02 476.228 1802.26 475.696 1802.49 475.105C1802.73 474.514 1802.97 473.922 1803.15 473.272C1803.56 471.912 1803.86 470.611 1804.04 469.31C1804.21 468.069 1804.27 466.886 1804.15 465.881C1804.1 464.876 1803.86 463.989 1803.56 463.397C1803.21 462.747 1802.79 462.333 1802.26 462.156C1801.96 462.097 1801.66 462.097 1801.31 462.156C1800.95 462.215 1800.65 462.392 1800.3 462.57C1799.94 462.806 1799.59 463.043 1799.23 463.397C1798.93 463.87 1798.58 464.225 1798.22 464.698Z"
          fill="url(#paint14_linear)"
        />
        <path
          d="M1781.2 463.338C1780.96 463.693 1780.66 464.048 1780.43 464.461C1780.19 464.875 1779.95 465.289 1779.71 465.762C1779.48 466.235 1779.24 466.708 1779.06 467.181C1778.88 467.654 1778.65 468.187 1778.47 468.719C1778.11 469.901 1777.82 471.025 1777.7 472.089C1777.52 473.153 1777.52 474.159 1777.58 475.046C1777.64 475.933 1777.82 476.701 1778.05 477.292C1778.35 477.884 1778.71 478.298 1779.18 478.475C1779.42 478.534 1779.71 478.593 1780.01 478.534C1780.31 478.475 1780.6 478.357 1780.9 478.179C1781.2 478.002 1781.49 477.766 1781.85 477.47C1782.15 477.174 1782.5 476.819 1782.8 476.465C1783.1 476.11 1783.33 475.755 1783.57 475.341C1783.81 474.927 1784.1 474.513 1784.34 474.04C1784.58 473.567 1784.82 473.094 1785.05 472.562C1785.29 472.03 1785.47 471.498 1785.65 470.966C1786 469.783 1786.3 468.601 1786.48 467.477C1786.65 466.354 1786.65 465.348 1786.6 464.461C1786.54 463.575 1786.36 462.806 1786.06 462.274C1785.76 461.682 1785.41 461.328 1784.87 461.209C1784.64 461.15 1784.34 461.15 1784.04 461.209C1783.75 461.269 1783.45 461.387 1783.15 461.564C1782.86 461.742 1782.56 461.978 1782.21 462.274C1781.79 462.569 1781.49 462.924 1781.2 463.338Z"
          fill="url(#paint15_linear)"
        />
        <path
          d="M1841.65 427.269C1841.53 426.856 1841.41 426.501 1841.23 426.087C1841.05 425.673 1840.88 425.259 1840.58 424.845C1840.34 424.431 1840.05 424.017 1839.75 423.544C1839.45 423.13 1839.1 422.717 1838.68 422.244C1837.85 421.357 1836.9 420.529 1835.89 419.819C1834.94 419.11 1833.93 418.578 1832.93 418.164C1831.92 417.75 1830.97 417.513 1830.08 417.395C1829.19 417.277 1828.42 417.395 1827.77 417.691C1827.41 417.868 1827.11 418.045 1826.88 418.341C1826.64 418.578 1826.46 418.873 1826.34 419.228C1826.22 419.583 1826.16 419.937 1826.16 420.351C1826.16 420.765 1826.22 421.179 1826.28 421.593C1826.34 422.007 1826.52 422.362 1826.7 422.776C1826.88 423.19 1827.05 423.603 1827.35 424.077C1827.59 424.49 1827.88 424.963 1828.24 425.377C1828.54 425.791 1828.95 426.264 1829.31 426.678C1830.2 427.624 1831.15 428.452 1832.16 429.162C1833.16 429.871 1834.17 430.403 1835.18 430.817C1836.19 431.231 1837.14 431.468 1838.03 431.586C1838.92 431.645 1839.69 431.586 1840.28 431.231C1840.64 431.054 1840.88 430.817 1841.11 430.581C1841.35 430.344 1841.47 429.989 1841.59 429.694C1841.71 429.339 1841.77 428.984 1841.77 428.57C1841.77 428.156 1841.71 427.743 1841.65 427.269Z"
          fill="url(#paint16_linear)"
        />
        <path
          d="M1896.34 481.255C1896.28 480.841 1896.17 480.368 1895.99 479.895C1895.81 479.422 1895.63 478.949 1895.33 478.476C1895.1 478.003 1894.8 477.53 1894.44 477.057C1894.09 476.584 1893.73 476.111 1893.32 475.638C1892.43 474.633 1891.36 473.746 1890.29 472.977C1889.22 472.208 1888.1 471.617 1886.97 471.203C1885.84 470.789 1884.78 470.494 1883.71 470.434C1882.7 470.375 1881.75 470.494 1880.98 470.848C1880.56 471.026 1880.21 471.321 1879.91 471.617C1879.61 471.913 1879.38 472.267 1879.26 472.681C1879.08 473.095 1878.96 473.509 1878.96 473.982C1878.9 474.455 1878.96 474.928 1879.02 475.46C1879.08 475.933 1879.2 476.347 1879.38 476.82C1879.55 477.293 1879.73 477.766 1880.03 478.239C1880.27 478.713 1880.56 479.245 1880.92 479.718C1881.28 480.191 1881.63 480.664 1882.11 481.196C1883.05 482.26 1884.12 483.147 1885.19 483.916C1886.32 484.685 1887.44 485.276 1888.63 485.749C1889.76 486.163 1890.89 486.458 1891.89 486.458C1892.9 486.518 1893.85 486.34 1894.62 485.985C1895.04 485.808 1895.39 485.512 1895.63 485.217C1895.93 484.921 1896.11 484.566 1896.28 484.152C1896.46 483.738 1896.52 483.325 1896.52 482.852C1896.46 482.26 1896.4 481.728 1896.34 481.255Z"
          fill="url(#paint17_linear)"
        />
      </g>
      <g filter="url(#filter2_f)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.545 1183.57L52.7864 1155.91L45 1116.59L81.7136 1080.71L146.745 1043.92L192.898 1031L223 1057.49L182.545 1183.57Z"
          fill="url(#paint18_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.54 1104.59L163.188 1057.92L198.292 1090.85L95.9814 1150L82.54 1104.59Z"
          fill="url(#paint19_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.2771 1161.3L52.7864 1155.91L45 1116.59L89.9785 1100.5L107.9 1149.91L78.2771 1161.3Z"
          fill="url(#paint20_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M192.898 1031L223 1057.49L215.04 1082.45L191.028 1101.68L157.055 1061.66L192.898 1031Z"
          fill="url(#paint21_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.545 1183.57L168.669 1180.66L98.5479 1142.65L197.466 1085.41L182.545 1183.57Z"
          fill="url(#paint22_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.546 1183.57L52.917 1155.91L103.376 1139.82L182.546 1183.57Z"
          fill="url(#paint23_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223 1057.62L182.632 1183.22L184.155 1093.06L223 1057.62Z"
          fill="url(#paint24_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.498 1180.27L57.6584 1155.87L70.0558 1151.87C91.8926 1164.17 120.863 1168.26 145.701 1165.52L173.498 1180.27Z"
          fill="url(#paint25_linear)"
        />
        <g opacity="0.66">
          <path
            opacity="0.66"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M181.893 1164.48L181.545 1181.61L159.752 1169.57C167.538 1168.96 175.02 1167.26 181.893 1164.48Z"
            fill="url(#paint26_linear)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.2219 1154.04L46.6965 1116.98L55.1354 1113.85C54.8744 1114.9 54.7439 1115.94 54.6134 1116.9C52.7865 1130.77 61.3994 1143.56 74.4927 1147.65L54.2219 1154.04Z"
          fill="url(#paint27_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.354 1046L187.156 1034L168.321 1049.48L178.5 1038.65L146.528 1047.79L126.823 1057.23L146.354 1046Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M176.586 1048.96L192.681 1033.78L217.388 1054.66L193.029 1037.83L176.586 1048.96Z"
          fill="white"
        />
        <path
          opacity="0.35"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.2415 1106.76L104.812 1136.77L126.214 1124.81C108.509 1125.77 103.985 1122.99 94.2415 1106.76Z"
          fill="url(#paint28_linear)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f"
        x="134"
        y="5"
        width="139"
        height="139"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter1_f"
        x="1725"
        y="368"
        width="223"
        height="233"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter2_f"
        x="10"
        y="999"
        width="247"
        height="216"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="203.608"
        y1="118.532"
        x2="203.608"
        y2="30"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AF650C" />
        <stop offset="1" stopColor="#FFE68D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="203.608"
        y1="110.939"
        x2="203.608"
        y2="37.5918"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AF650C" />
        <stop offset="1" stopColor="#FFE68D" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="1755.25"
        y1="484.266"
        x2="1919.05"
        y2="484.266"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="1822.49"
        y1="477.261"
        x2="1936.86"
        y2="407.041"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="1721.76"
        y1="585.792"
        x2="1901.08"
        y2="491.554"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="1754.98"
        y1="470.79"
        x2="1814.95"
        y2="470.79"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="1872.41"
        y1="438.695"
        x2="1887.97"
        y2="438.695"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="1830.95"
        y1="464.139"
        x2="1848.56"
        y2="464.139"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="1783.66"
        y1="536.325"
        x2="1803.44"
        y2="536.325"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="1842.28"
        y1="552.306"
        x2="1861.05"
        y2="552.306"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1="1827.47"
        y1="528.592"
        x2="1847.16"
        y2="528.592"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint11_linear"
        x1="1812.47"
        y1="502.556"
        x2="1833.19"
        y2="502.556"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint12_linear"
        x1="1872.62"
        y1="520.903"
        x2="1892.27"
        y2="520.903"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint13_linear"
        x1="1760.82"
        y1="467.595"
        x2="1769.38"
        y2="467.595"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint14_linear"
        x1="1794.02"
        y1="472.08"
        x2="1804.26"
        y2="472.08"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint15_linear"
        x1="1777.49"
        y1="469.842"
        x2="1786.51"
        y2="469.842"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint16_linear"
        x1="1826.23"
        y1="424.493"
        x2="1841.8"
        y2="424.493"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint17_linear"
        x1="1878.84"
        y1="478.34"
        x2="1896.45"
        y2="478.34"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint18_linear"
        x1="139.192"
        y1="1009.39"
        x2="129.662"
        y2="1152.06"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint19_linear"
        x1="174.233"
        y1="1047.25"
        x2="94.9795"
        y2="1165.56"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint20_linear"
        x1="87.3795"
        y1="1110.67"
        x2="46.0371"
        y2="1196.12"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint21_linear"
        x1="207.268"
        y1="998.777"
        x2="178.212"
        y2="1101.33"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint22_linear"
        x1="140.796"
        y1="1103.01"
        x2="197.758"
        y2="1190.15"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint23_linear"
        x1="120.808"
        y1="1156.17"
        x2="113.211"
        y2="1189.55"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint24_linear"
        x1="257.347"
        y1="1079.04"
        x2="167.479"
        y2="1147.21"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint25_linear"
        x1="59.0504"
        y1="1150.2"
        x2="173.946"
        y2="1178.48"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint26_linear"
        x1="160.131"
        y1="1168.03"
        x2="183.477"
        y2="1173.78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint27_linear"
        x1="43.2289"
        y1="1131.14"
        x2="76.5304"
        y2="1139.34"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE68D" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
      <linearGradient
        id="paint28_linear"
        x1="91.0787"
        y1="1119.68"
        x2="125.41"
        y2="1128.12"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00558659" stopColor="#C08027" />
        <stop offset="1" stopColor="#AF650C" />
      </linearGradient>
    </defs>
  </svg>
)

export default ParallaxImageOne
